import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Import each language strings file
import en from "../lang/en.json"
import da from "../lang/da.json"
import zh_tw from "../lang/zh_tw.json"

export { useTranslation } from "react-i18next"

export enum Language {
  English = "en",
  Danish = "da",
  ChineseTaiwan = "zh_tw",
}

export const currentLanguage =
  (process.env.GATSBY_LANG as Language) || Language.English

const resources: Record<Language, any> = { en, da, zh_tw }

i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage,
  interpolation: {
    escapeValue: false,
  },
})

export const t = i18n.t

export function pathForLang(path: string): string {
  const initialPath = path.startsWith("/") ? path.substring(1) : path
  const finalPath =
    currentLanguage === Language.English
      ? initialPath
      : initialPath.substring(initialPath.indexOf("-") + 1)
  return "/" + finalPath
}

const slugPrefixPattern = Object.values(Language).join("|")

export const localizedSlugPattern =
  currentLanguage === Language.English
    ? `^(?!(${slugPrefixPattern})-)`
    : `^${currentLanguage}-`
