import { Language } from "./i18n"

const theme = {
  colors: {
    dark: "#1d1d1d",
    highlight: "#b4fba8",
  },
  fonts: {
    body: "Arimo",
    header: "DIN W01 Cond Bold",
    special: "Druk Wide Web",
    emphasis: "Druk XCond Cy Web",
    condensed: "Druk XCond Cy Web",
  },
  breakpoints: {
    mobile: 0,
    tablet: 768,
    desktop: 1024,
    desktophd: 1280,
  },
  mq: {
    mobile: "@media (max-width: 767px)",
    tablet: "@media (min-width: 768px) and (max-width: 1023px)",
    desktop: "@media (min-width: 1024px) and (max-width: 1279px)",
    desktophd: "@media (min-width: 1280px)",
    tall: "@media (min-height: 100vw)",
    wide: "@media (min-width: 100vh)",
  },
  wordpressUrl: "https://ieomd.halseyburgund.com",
}
export default theme

export const fullWidth = {
  width: "100%",
  [theme.mq.desktophd]: {
    width: 1000,
  },
  [theme.mq.desktop]: {
    width: 1000,
  },
  [theme.mq.tablet]: {
    width: 700,
  },
}

export const globalStyles = {
  "*": {
    boxSizing: "border-box",
  },
  html: {
    minWidth: "initial !important",
  },
  body: {
    margin: 0,
    backgroundColor: theme.colors.dark,
    fontFamily: theme.fonts.body,
    color: "white",
  },
  // TODO override this example style for danish
  // Add this pseudoselector to any element.
  // [`body:lang(${Language.Danish})`]: {
  //   color: "lightblue",
  // },
  "h1, h2, h3, h4, nav, header, address": {
    fontFamily: theme.fonts.header,
    textTransform: "uppercase",
  },
  "h1, h2, h3, h4": {
    textAlign: "center",
  },
  h1: {
    fontSize: "3rem",
    fontWeight: "normal",
  },
  main: {
    width: "100%",
    // height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    backgroundColor: theme.colors.dark,
  },
  a: {
    textDecoration: "none",
    color: "white",
    "&:hover": { color: theme.colors.highlight },
    "&:focus": { outline: "1px dotted #909090" },
  },
  // Remove browser default styles from all buttons.
  button: {
    fontSize: "100%",
    fontFamily: "inherit",
    border: 0,
    padding: 0,
    background: "none",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": { outline: "none" },
    // Custom global button styles
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  img: {
    height: "auto",
  },
  div: {
    "&:focus": { outline: "1px dotted #909090" },
  },
  ".container_wrap": {
    borderTopStyle: "none !important",
  },
}
