import { t } from "./i18n"

export type MenuData = {
  items: MenuItem[]
}

export type MenuItem = {
  label: string
  uri: string
}

const menuItems: MenuItem[] = [
  {
    label: t("menu.film"),
    uri: "/film#question",
  },
  {
    label: t("menu.resources"),
    uri: "/resources",
  },
  {
    label: t("menu.about"),
    uri: "/about",
  },
]

export default menuItems
